@import "~react-image-gallery/styles/css/image-gallery.css";
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2 {
  font-size: 48px;
  color: #5c5c5c;
  position: relative;
}

.btn-eco {
  text-align: center;
  min-width: 100px;
  background: linear-gradient(90.74deg, #f47c20 4.03%, #ffb882 120.23%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
  color: white;
  padding: 8px 32px;
  margin-top: 32px;
  transition: all 0.2s ease;
}

.btn-eco:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.35);
  color: white;
  transition: all 0.2s ease;
}
.product-box img {
  height: 80%;
  width: auto;
}
.product-box h4 {
  bottom: -50px !important;
  transition: all 0.2s ease;
}

.product-box:hover h4 {
  bottom: 0 !important;
  transition: all 0.2s ease;
}

.btn-filter {
  text-align: right;
  padding-right: 24px;
  width: 100%;
  margin: auto;
  line-height: 2.5rem;
  cursor: pointer;
  position: relative;
}
.btn-filter-active {
  color: white;
  background-color: #f47c20;
}
.btn-filter-active::before {
  position: absolute;
  content: "";
  width: 100%;
  transform: translateX(-150px);
  height: 100%;

  z-index: -1;
}

h2 {
  font-weight: 900;
  color: #5c5c5c;
  margin: 0;
}

.white {
  color: white;
}

.drop-down-menu {
  padding: 16px 64px;
  width: 100%;
  background-color: white;
  position: absolute;
  left: 0;
  z-index: 2000;
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.08);
}

.drop-down-menu ul {
  padding-left: 0;
  list-style-type: none;
}

.drop-down-menu ul a {
  color: black;
}
.solution-s1 {
  width: 100vw;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
}
.solution-s1 h2 {
  font-weight: 900;
  font-size: 72px;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  color: #f47c20;
}

.course-section h2::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 10px;
  bottom: 0;
  left: calc(50% - 50px);
  background-color: #f47c20;
  z-index: -1;
  margin: auto;
}

.course-section p {
  white-space: pre-wrap;
}

.solution-section,
.course-section {
  padding: 32px;
}
.solution-section h2,
.course-section h2 {
  color: #969696;
  font-size: 38px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.solution-section h3,
.course-section h3 {
  color: #969696;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.slick-dots button {
  background: #f47c20 !important;
  height: 8px !important;
}

.slick-dots button::before {
  height: 8px !important;
}

.course-section {
  max-width: 1200px;
  overflow: hidden;
  margin: auto;
}
.course-section-title-styled {
  position: relative;
}

.slick-dots {
  margin: 0;
  bottom: 0 !important;
}

.slick-dots li button::before {
  content: "";
}

.ant-carousel .slick-dots {
  margin: 0;
}

@media screen and (max-width: 900px) {
  body {
    font-size: 14px;
  }
  .btn-filter {
    text-align: left !important;
    padding-right: 0 !important;
    padding-left: 24px;
    width: 100%;
  }
  .filter-group {
    width: 100%;
    margin-bottom: 18px;
  }

  h2 {
    font-size: 32px;
    text-align: center !important;
  }

  .btn-eco {
    margin-top: 8px;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
  }

  .product-box h4 {
    bottom: 0 !important;
  }
}

.private-policy {
  max-width: 800px;
  margin: auto;
}

.private-policy h2 {
  font-size: 1.5rem;
}

.ant-spin-dot-item {
  color: #f47c20 !important;
  background-color: #f47c20 !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  border-bottom-color: #f47c20 !important;
}
.ant-table-cell {
  white-space: pre-wrap;
}
